@font-face {
    font-family: 'trisotech-product';
    src: url('../font/trisotech-product.ttf') format('truetype'),
         url('../font/trisotech-product.woff') format('woff'),
         url('../font/trisotech-product.svg#trisotech-product') format('svg');

    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}



[class^="tp-icon-"] {
    font-family: 'trisotech-product';
    speak: none;
}

.generate-product(@name, @contentColor, @contentBlank, @color) {
    .tp-icon-@{name} {
        &:before {
            content: @contentColor;
        }
        &.colored:before {
            color: @color
        }
        &.empty:before {
            content: @contentBlank;
        }
    }
    .tp-color-@{name} {
        color: @color;
    }
    .tp-background-color-@{name} {
        background-color: @color;
    }

}

@bpmn-color: #0866AC;
@cmmn-color: #16884A;
@da-color: #ED5724;
@dmn-color: #EFA91B;
@bpmnjit-color: #5F3577;
@oceb-color: #5F3577;
@eurent-color: #12959F;
@ia-color: #238ACC;
@bpmnqg-color: #12959F;
@ra-color: #E72C2E;
@bpmnrb-color: #12959F;
@bpmnv-color: #0866AC;
@help-color: #12959F;
@kom-color: #E72C2E;
@landscaping-color: #E72C2E;
@cap-color: #7b67ae;
@ideation-color: #009EB6;
@be-color: #e5185c;
@pmml-color: #848484;
@graph-color: #009eb6;
@sl-color: #70ad47;
@admin-color: #009eb6;
@ce-color: #009eb6;
@sdmn-color: #147598;


.generate-product(bpmn, "\e600", "\e60c", @bpmn-color);
.generate-product(cmmn, "\e601", "\e60d", @cmmn-color);
.generate-product(da, "\e602", "\e60e", @da-color);
.generate-product(dmn, "\e603", "\e60f", @dmn-color);
.generate-product(bpmnjit, "\e604", "\e610", @bpmnjit-color);
.generate-product(oceb, "\e604", "\e610", @oceb-color);
.generate-product(eurent, "\e605", "\e605", @eurent-color);
.generate-product(ia, "\e606", "\e611", @ia-color);
.generate-product(bpmnqg, "\e607", "\e612", @bpmnqg-color);
.generate-product(ra, "\e608", "\e613", @ra-color);
.generate-product(bpmnrb, "\e609", "\e614", @bpmnrb-color);
.generate-product(bpmnv, "\e60b", "\e60c", @bpmnv-color);
.generate-product(help, "\e60a", "\e615", @help-color);
.generate-product(kom, "\e900", "\e901", @kom-color);
.generate-product(landscaping, "\e902", "\e903", @landscaping-color);
.generate-product(capability, "\e904", "\e905", @cap-color);
.generate-product(ideation, "\e906",  "\e907", @ideation-color);
.generate-product(be, "\e908",  "\e914", @be-color);
.generate-product(pmml, "\e90c",  "\e915", @pmml-color);
.generate-product(graph, "\e90d",  "\e90d", @graph-color);
.generate-product(sl, "\e90e",  "\e90f", @sl-color);
.generate-product(admin, "\e910",  "\e911", @admin-color);
.generate-product(ce, "\e912",  "\e913", @ce-color);
.generate-product(sdmn, "\e91d",  "\e91e", @sdmn-color);

